declare var ALGOLIA_INSIGHTS_TOKEN: string;
declare var hktvCommon: any;
declare var indices: any;

interface KeywordSearchSuggestionResponse {
    keyword: string;
    keywordDataList: Array<KeywordSearchData>; //suggestCategories
    storeDataList: Array<KeywordSearchStoreSearchData>; //suggestStores
    productDataList: Array<KeywordSearchProductSearchData>;
    keywordSuggestDataList: Array<KeywordSearchData>;
}

interface KeywordSearchStoreSearchData {
    code: string;
    alt: string;
    url: string;
}

interface KeywordSearchProductSearchData {
    name: string;
    code: string;
}

interface KeywordSearchData {
    keyword: string;
    name: string;
    code: string;
}

interface KeywordSearchFilters {
    keyword: string;
    sort: string;
    page: number;
    productsPerPage: number;
    category: string;
    bannerCategory: string;
    brand: Array<string>;
    countryOfOrigin: Array<string>;
    color: Array<string>;
    deliverableRegion: Array<string>;
    price: string;
    deliveryTime: Array<string>;
    mainlandProducts: Array<String>;

    productIdList?: Array<string>;
    productIdSearchSize?: number;

    purchasedList?: Array<string>;
    purchasedListSearchSize?: number;
    sourceId?: string;

    priorSKUListSearchSize?: number;

    salesChannel?: Array<string>
}

interface KeywordSearchResponse {
    keyword: string;
    products: Array<ProductAdapter>;
    pagination: AlgoliaSearchPagination;
    categoryTree: CategoryTree;
    filterBreadcrumb: Array<CategoryElement>;
    brands: Array<BrandFacetValue>;
    countryOfOrigins: Array<CountryOfOriginFacetValue>;
    colors: Array<ColorFacetValue>;
    deliverableRegions: Array<DeliverableRegionFacetValue>;
    prices: Array<PriceFacetValue>;
    promotionBanner: PromotionBanner;
    keywordBanner: KeywordBanner;
    userData;
    deliverySchedules: Array<deliveryTimesFacetValue>;
    mainlandProducts: Array<MainlandProductsFacetValue>;
}

interface PromotionBanner {
    alt: string;
    category: string;
    url: string;
    imageUrl: string;
    backgroundColor: string;
    promoDetails: string;
    promoTitle: string;
    tandCLink: string;
    mabsRefId: string;
}

interface KeywordBanner {
    alt: string;
    backgroundColor: string;
    bannerUrl: string;
    imageUrlWeb: string;
    category: string;
    promoDetails: string;
    promoTitle: string;
    tandCLink: string;
    mabsRefId: string;
}

interface ProductAdapter {
    code: string;
    objectID: string;
    queryID: string;
    baseProduct: string;
    primaryCatCode: string;
    nameZh: string;
    nameEn: string;
    brand: string;
    brandEn: string;
    brandZh: string;
    descriptionEn: string;
    descriptionZh: string;
    storeCode: string;
    storeCodeOrig: string;
    storeNameEn: string;
    storeNameZh: string;
    storeType: string;
    storeTypeDisplayZh: string;
    storeTypeDisplayEn: string;
    storeRating: number;
    averageRating: number;
    numberOfReviews: number;
    thresholdPromotionEn: any;
    thresholdPromotionZh: any;
    buyMoreSaveMoreEn: any;
    buyMoreSaveMoreZh: any;
    perfectPartnerPromotionEn: any;
    perfectPartnerPromotionZh: any;
    bundlePromotionEn: any;
    bundlePromotionZh: any;
    promotionStyle: string;
    promotionTextEn: string;
    promotionTextZh: string;
    priceList: Array<any>;
    mainlandPriceList: Array<any>;
    savedPrice: Array<any>;
    priceUpdatedDate: string;
    images: Array<any>;
    videos?: {};
    labels: Array<any>;
    stock: {};
    hasStock: boolean;
    hasVariantInStock: boolean;
    invisible: boolean;
    isPrimaryVariant: boolean;
    numberOfVariants: number;
    purchaseOption: number;
    packingSpecEn: string;
    packingSpecZh: string;
    urlEn: string;
    urlZh: string;
    deliveryLabelCode: string;
    deliveryLabelScheduleCode: any;
    deliverableRegions: Array<any>;
    salesNumberStringZh: any;
    salesNumberStringEn: any;
    mabsRefId?: string;
    mabsAdPosition?: any;
    marketingLabelUids?: any;
    additionalLabel?: string;
    inStorePickUpCheckoutLink?: string;
    externalAffiliateRedirectLink?: string;
    displayStoreSquareLogoUrl?: string;
    officialWebsiteAffiliateUrl?: string;
    additionalLabelForPurchasedList?: string;
    sourceId?: string;
    overseasRegionCodes: Array<any>;
    isThirdPartyLogisticsWarehouse: string;
    isPriorSKU: string;
    pickLabelUids: Array<string>;
    eCoinsRebateSchedule: Array<eCoinsRebateSchedule>;
    isMainlandSamePrice: string;
}

interface eCoinsRebateSchedule {
    startTime: number;
    endTime: number;
    rebatePercentage: string;
}

interface AlgoliaSearchPagination {
    totalProductCount: number;  // PaginationResponse.totalNumberOfResults
    totalPageNo: number;    // PaginationResponse.numberOfPages
    pageNo: number;  // PaginationResponse.currentPage
    productPerPage: number;  // PaginationResponse.pageSize
}

interface CategoryFacetValue {
    parentCode: string;
    code: string;
    nameZh: string;
    nameEn: string;
    count: number;
}

interface LeafCategoryFacetValue {
    mainCode: string;
    parentCode: string;
    categories: Array<CategoryElement>;
    count: number;
}

interface CategoryElement {
    code: string;
    name: string;
}

interface CategoryTree {
    filterDepth: number;
    categories: Array<CategoryTreeElement>;
}

interface CategoryTreeElement {
    code: string;
    name: string;
    count: number;
    child: Array<CategoryTreeElement>;
}

interface BrandFacetValue {
    code: string;
    name: string;
    priority: string;
    count: number;
}

interface CountryOfOriginFacetValue {
    code: string;
    name: string;
    count: number;
}

interface ColorFacetValue {
    code: string;
    name: string;
    imageUrl: string;
    count: number;
}

interface DeliverableRegionFacetValue {
    code: string;
    name: string;
    count: number;
}

interface PriceFacetValue {
    price: string
    count: number;
}

interface deliveryTimesFacetValue {
    code: string;
    name: string;
    count: number;
}

interface MainlandProductsFacetValue {
    code: string;
    name: string;
    count: number;
}

interface pinData {
    position: number;
    id: string;
}

interface indexMap {
    hktvProductIndice?: string;
    purchasedIndices?: string;
    recentlyViewedIndices?: string;
    thirdPartyLogisticIndices?: string;
    hktvPromotionBannersIndice?: string;
    hktvProductHotFeaturedWallIndice?: string;
    hktvProductStoreNameReplicaIndice?: string;
    hktvProductQuerySuggestionsIndice?: string;
}

class KeywordSearchAdapter {
    static hitsPerPage: number = 60;
    static hitsPerRecentSearch: number = 2;
    static hitsPerPurchasedListSearch: number = 2;
    static maxValuesPerFacet: number = 1000;
    static maxValuesPerSuggestCategory1Facet: number = 5;

    public static getNowDateHour(): string {
        let currentDate = new Date(Date.now());
        let dd = currentDate.getDate() < 10 ? '0' + currentDate.getDate().toString() : currentDate.getDate().toString();
        let hh = currentDate.getHours() < 10 ? '0' + currentDate.getHours().toString() : currentDate.getHours().toString();
        return dd + hh;
    }

    static addPromotionBannerSearch(categoryCode: string, searchQueries: Array<any>) {
        let indexMap = this.indexNameMapping();
        searchQueries.push({
            indexName: indexMap.hktvPromotionBannersIndice,
            keyword: categoryCode,
            highlight: ["*"],
            filterConditionalExpression: "bannerType:A",
            page: {
                pageNumber: 0,
                pageSize: 1
            }
        });
    }

    static primCatForPromotionBanner(normalProduct: any) {
        var product = normalProduct.find(product => {
            if (typeof product.source.priceList.find(price => price.value <= 0) == "undefined") {
                return product.source;
            }
        });
        if (product && typeof product != 'undefined' && product.source.primaryCatCode && typeof product.source.primaryCatCode != 'undefined') {
            return product.source.primaryCatCode.substring(0, 6).concat("0000000")
        }
        return "";
    }

    static addPriorSKUSearch(searchJson: KeywordSearchFilters, withPriorSKUSearch, searchQueries, thirdPartyLogisticIndex, keyword, filterObject, pageNo) {
        //get product from threePartyLogisticIndices
        let filterString = 'isPriorSKU: true';
        if (searchJson.priorSKUListSearchSize > 0) {
            searchQueries.push({
                indexName: thirdPartyLogisticIndex,//sortIndex,
                keyword: keyword,
                attributesToRetrieve: ["*"],
                filter: filterObject,
                filterConditionalExpression: filterString,
                skipRulePin: true,
                range: KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) ? KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) : "",
                page: {
                    pageNumber: pageNo,
                    pageSize: (searchJson.priorSKUListSearchSize ? searchJson.priorSKUListSearchSize : this.hitsPerRecentSearch),
                }
            });
        }
    }

    static indexNameMapping(): indexMap {
        let indexMap : indexMap = {};
        if (indices) {
            for (var i in indices) {
                if (indices[i].indice && indices[i].indexName) {
                    indexMap[indices[i].indice] = indices[i].indexName;
                }
            }
        }
        return indexMap;
    }

    /**
     * Send an plain keyword search request
     * @param {string} keyword
     * @return {object} promises for adding callbacks by user(no response parse performed)
     */

    public static sendSearchRequest(searchJson: KeywordSearchFilters): any {
        let keyword = searchJson.keyword || '';
        let filterObject = this.constructSearchFiltersString(searchJson);
        let pageNo = searchJson.page || 0;

        let searchQueries = new Array();
        let withProductIdSearch = false;
        let withPurchasedListSearch = false;
        let withPriorSKUSearch = false;
        let indexMap = this.indexNameMapping();
        let sortIndex = indexMap.hktvProductIndice;
        if (searchJson['sort']) sortIndex = searchJson['sort'];

        // possible searchQueries array index
        // -1: categoryStructureLevel1Display for upto zone cateogry filter
        // 0: (mandatory) initial search request
        // 1: promotion banner
        // 2-5: brand, countryOfOrigin, color, price escape facet request
        // >6: leaf cat search
        // last index: isPriorSKU search

        searchQueries.push({
            indexName: sortIndex,
            keyword: searchJson.keyword || '',
            attributesToRetrieve: ["*"],
            aggregations: ["*"],
            filter: filterObject,
            range: KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) ? KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) : "",
            page: {
                pageNumber: searchJson.page || 0,
                pageSize: 60
            }
        });

        if (Array.isArray(searchJson.productIdList) && searchJson.productIdList.length > 0 && indexMap.hktvProductIndice) {
            withProductIdSearch = true;
            searchQueries.push({
                indexName: indexMap.recentlyViewedIndices ,//sortIndex,
                keyword: searchJson.keyword || '',
                attributesToRetrieve: ["*"],
                aggregations: [],
                filter: this.constructSearchFiltersString(searchJson, null, true),
                page: {
                    pageNumber: 0,
                    pageSize: (searchJson.productIdSearchSize ? searchJson.productIdSearchSize : this.hitsPerRecentSearch),
                }
            });
        }

        if (Array.isArray(searchJson.purchasedList) && searchJson.purchasedList.length > 0 && indexMap.hktvProductIndice) {
            withPurchasedListSearch = true;
            searchQueries.push({
                indexName: indexMap.purchasedIndices,
                keyword: searchJson.keyword || '',
                attributesToRetrieve: ["*"],
                aggregations: [],
                filter: this.constructSearchFiltersString(searchJson, null, false, true),
                page: {
                    pageNumber: 0,
                    pageSize: (searchJson.purchasedListSearchSize ? searchJson.purchasedListSearchSize : this.hitsPerPurchasedListSearch),
                }
            });
        }

        // Promotion Banner
        if (searchJson.category || searchJson.bannerCategory) {
            const {category, bannerCategory} = searchJson;
            const bannerCat = category ? category : bannerCategory;
            this.addPromotionBannerSearch(bannerCat, searchQueries);
        }

        // addition search request to get potential facets when facets filter exists
        if (this.constructSearchFiltersString(searchJson, 'category')) {
            let escapeFacets = {
                'brand': searchJson.brand || [],
                'countryOfOrigin': searchJson.countryOfOrigin || [],
                'color': searchJson.color || [],
                'deliverableRegion': searchJson.deliverableRegion || [],
                'price': searchJson.price || ''
            }

            for (let key in escapeFacets) {
                if (searchJson[key] && searchJson[key].length > 0) {
                    searchQueries.push({
                        indexName: sortIndex,
                        keyword: searchJson.keyword || '',
                        attributesToRetrieve: ["*"],
                        aggregations: ['brandDisplay', 'countryOfOriginDisplay', 'colorDisplay', 'deliverableRegions.deliverableRegionDisplay', 'sellingPriceRange'],
                        filter: this.constructSearchFiltersString(searchJson, key),
                        range: this.getRangeOfSellingPrice(searchJson) && key != 'price' ? KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) : null,
                        page: {
                            pageNumber: searchJson.page || 0,
                            pageSize: 1
                        }
                    });
                }
            }
        }

        if (!searchJson.category || searchJson.category == '') {
            return fetch(hktvCommon.env.keywordSearchServerDomainUrl + "/api/search", {
                method: 'POST',
                headers: {
                    "accept": "application/json",
                    'Content-Type': 'application/json',
                    'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
                },
                body: JSON.stringify({
                    requests: [{
                        indexName: sortIndex,
                        keyword: searchJson.keyword || '',
                        attributesToRetrieve: ["*"],
                        aggregations: ['categoryStructureLevel1Display'],
                        filter: filterObject,
                        page: {
                            pageSize: 1,
                        }
                    }, {
                        // getting first 3 skus for cat maping in promotion banner
                        indexName: sortIndex,
                        keyword: searchJson.keyword || '',
                        attributesToRetrieve: ["*"],
                        aggregations: [],
                        filterConditionalExpression: "",
                        skipRulePin: true,
                        page: {
                            pageNumber: 0,
                            pageSize: 1,
                        }
                    }]
                }),
            })
                .then(response => response.json())
                .then(data => {
                    // Handle the response data
                    if (data.message == "Success") {
                        let facets = null, catLevel1Displays = null;

                        if (data.results.length > 0) {
                            facets = data.results[0].aggregations;
                            catLevel1Displays = facets.categoryStructureLevel1Display || {};
                        }

                        let catLevel1FurtherSearch: any = [];
                        for (let key in catLevel1Displays) {
                            let catLevel1Display = JSON.parse(key);
                            let catLevel0 = '', catLevel1 = '';
                            if (catLevel1Display.length >= 2) {
                                catLevel0 = catLevel1Display[0].code || '';
                                catLevel1 = catLevel1Display[1].code || '';
                            }
                            let count = catLevel1Displays[key];

                            // find main category with most products per street/zone
                            if (!catLevel1FurtherSearch[catLevel0])
                                catLevel1FurtherSearch[catLevel0] = {'code': catLevel1, 'count': count};
                            else if (count > catLevel1FurtherSearch[catLevel0].count)
                                catLevel1FurtherSearch[catLevel0] = {'code': catLevel1, 'count': count};
                        }

                        for (let key in catLevel1FurtherSearch) {// query leaf categories of filtered main category above
                            let category = this.constructSearchFiltersString(searchJson).category || [];
                            category.push(catLevel1FurtherSearch[key].code)
                            searchQueries.push({
                                indexName: sortIndex,
                                keyword: searchJson.keyword || '',
                                attributesToRetrieve: ["*"],
                                aggregations: ['categoryStructureLevel1Display'],
                                filter: {
                                    ...this.constructSearchFiltersString(searchJson),
                                    "category": category
                                },
                                page: {
                                    pageSize: 1,
                                }
                            });
                        }

                        let mabsData = data.results[0].userData ? (typeof data.results[0].userData[0] !== "undefined" && typeof data.results[0].userData[0].mabsData !== "undefined" ? data.results[0].userData[0].mabsData : null) : null;

                        if (data.results[1].hits && data.results[1].hits.length > 0) {
                            const primCatForPromotionBanner = this.primCatForPromotionBanner(data.results[1].hits) || "";
                            if (primCatForPromotionBanner && primCatForPromotionBanner != "") {
                                this.addPromotionBannerSearch(primCatForPromotionBanner, searchQueries);
                            }
                        }

                        if (searchJson.priorSKUListSearchSize > 0 && indexMap.thirdPartyLogisticIndices && typeof indexMap.thirdPartyLogisticIndices !== "undefined") {
                            withPriorSKUSearch = true;
                            KeywordSearchAdapter.addPriorSKUSearch(searchJson, withPriorSKUSearch, searchQueries, indexMap.thirdPartyLogisticIndices, keyword, this.constructSearchFiltersString(searchJson), pageNo);
                        }

                        return fetch(hktvCommon.env.keywordSearchServerDomainUrl + "/api/search", {
                            method: 'POST',
                            headers: {
                                "accept": "application/json",
                                'Content-Type': 'application/json',
                                'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
                            },
                            body: JSON.stringify({
                                requests: searchQueries
                            }),
                        }).then(response => response.json())
                            .then(data => {
                                // Handle the response data
                                if (data.message == "Success") {
                                    if (withProductIdSearch) {
                                        data = {
                                            ...data,
                                            withProductIdSearch
                                        };
                                    }
                                    if (withPurchasedListSearch) {
                                        data = {
                                            ...data,
                                            withPurchasedListSearch
                                        };
                                    }
                                    if (withPriorSKUSearch) {
                                        data = {
                                            ...data,
                                            withPriorSKUSearch,
                                        };
                                    }
                                    return KeywordSearchAdapter.parseKWSResponse(data, searchJson);
                                }
                            })


                    }
                    return null;
                })
                .catch(error => {
                    // Handle errors
                    console.log('error: ', error)
                });
        }

        if (this.isZoneCategory(searchJson.category)) {
            return fetch(hktvCommon.env.keywordSearchServerDomainUrl + "/api/search", {
                method: 'POST',
                headers: {
                    "accept": "application/json",
                    'Content-Type': 'application/json',
                    'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
                },
                body: JSON.stringify({
                    requests: [{
                        indexName: sortIndex,
                        keyword: searchJson.keyword || '',
                        attributesToRetrieve: ['*'],
                        aggregations: ['categoryStructureLevel1Display'],
                        filter: filterObject,
                        range: KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) ? KeywordSearchAdapter.getRangeOfSellingPrice(searchJson) : "",
                        page: {
                            pageSize: 1,
                        }
                    }]
                })
            }).then(response => response.json())
                .then(data => {
                    // TODO: refine code : get most cat 1
                    let facets = null, catLevel1Displays = null, searchFilters = null;

                    if (data.results.length > 0) {
                        facets = data.results[0].aggregations;
                        catLevel1Displays = facets.categoryStructureLevel1Display ? facets.categoryStructureLevel1Display : {};
                        searchFilters = searchJson;
                    }
                    let searchFilterCategory = searchFilters.category || '';

                    let catLevel1FurtherSearch: any = {};
                    for (let key in catLevel1Displays) {
                        let catLevel1Display = JSON.parse(key);
                        let catLevel0 = '', catLevel1 = '';
                        if (catLevel1Display.length >= 2) {
                            catLevel0 = catLevel1Display[0].code || '';
                            catLevel1 = catLevel1Display[1].code || '';
                        }
                        let count = catLevel1Display[key];

                        // find main category with most products per street/zone
                        if (catLevel0 == searchFilters.category) {
                            if (!catLevel1FurtherSearch.code)
                                catLevel1FurtherSearch = {'code': catLevel1, 'count': count};
                            else if (count > catLevel1FurtherSearch.count)
                                catLevel1FurtherSearch = {'code': catLevel1, 'count': count};
                        }
                    }

                    // query leaf categories of filtered main category above
                    let category = this.constructSearchFiltersString(searchJson).category || [];
                    category.push(catLevel1FurtherSearch.code)
                    searchQueries.push({
                        indexName: sortIndex,
                        keyword: searchJson.keyword || '',
                        attributesToRetrieve: ['*'],
                        aggregations: ['categoryStructureDisplay'],
                        filter: {
                            ...this.constructSearchFiltersString(searchJson, 'category'),
                            "category": category
                        },
                        page: {
                            pageSize: 1,
                        }
                    });

                    let mabsData = data.results[0].userData ? (typeof data.results[0].userData[0] !== "undefined" && typeof data.results[0].userData[0].mabsData !== "undefined" ? data.results[0].userData[0].mabsData : null) : null;

                    if (searchJson.priorSKUListSearchSize > 0) {
                        withPriorSKUSearch = true;
                        KeywordSearchAdapter.addPriorSKUSearch(searchJson, withPriorSKUSearch, searchQueries, indexMap.thirdPartyLogisticIndices, keyword, filterObject, pageNo);
                    }
                    return fetch(hktvCommon.env.keywordSearchServerDomainUrl + "/api/search", {
                        method: 'POST',
                        headers: {
                            "accept": "application/json",
                            'Content-Type': 'application/json',
                            'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
                        },
                        body: JSON.stringify({
                            requests: searchQueries
                        })
                    })
                }).then(response => response.json())
                .then(data => {
                    // Handle the response data
                    if (data.message == "Success") {
                        if (withProductIdSearch) {
                            data = {
                                ...data,
                                withProductIdSearch
                            };
                        }
                        if (withPurchasedListSearch) {
                            data = {
                                ...data,
                                withPurchasedListSearch
                            };
                        }
                        if (withPriorSKUSearch) {
                            data = {
                                ...data,
                                withPriorSKUSearch,
                            };
                        }
                        return KeywordSearchAdapter.parseKWSResponse(data, searchJson);
                    }
                })

        }

        if (searchJson.priorSKUListSearchSize > 0 && indexMap.thirdPartyLogisticIndices) {
            withPriorSKUSearch = true;
            KeywordSearchAdapter.addPriorSKUSearch(searchJson, withPriorSKUSearch, searchQueries, indexMap.thirdPartyLogisticIndices, keyword, filterObject, pageNo);
        }
        return fetch(hktvCommon.env.keywordSearchServerDomainUrl + "/api/search", {
            method: 'POST',
            headers: {
                "accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
            },
            body: JSON.stringify({
                requests: searchQueries
            })
        }).then(response => response.json())
            .then(data => {
                // Handle the response data
                if (data.message == "Success") {
                    if (withProductIdSearch) {
                        data = {
                            ...data,
                            withProductIdSearch
                        };
                    }
                    if (withPurchasedListSearch) {
                        data = {
                            ...data,
                            withPurchasedListSearch
                        };
                    }
                    if (withPriorSKUSearch) {
                        data = {
                            ...data,
                            withPriorSKUSearch,
                        };
                    }
                    return KeywordSearchAdapter.parseKWSResponse(data, searchJson);
                }
            })
    }

    public static sendSuggestRequest(keyword: string): any {
        let searchQueries = new Array();
        let indexMap = this.indexNameMapping() || null;
        searchQueries.push({
			indexName: indexMap.hktvProductIndice || "hktvProduct",
			keyword: keyword || '',
			filter: {
				allProductSearchCode: [keyword]
			},
			skipAdditionalCondition: true,
            page: {
                pageNumber: 0,
                pageSize: 1
            }
        })
        searchQueries.push({
            aggregations: ["*"],
            indexName: indexMap.hktvProductQuerySuggestionsIndice || "hktvproduct-query-suggestions",
            keyword: keyword || '',
            page: {
                pageNumber: 0,
                pageSize: 3
            },
            skipAdditionalCondition: true

        });
        searchQueries.push({
            aggregations: ["categoryStructureLevel1Display"],
            indexName: indexMap.hktvProductIndice || "hktvProduct",
            keyword: keyword || '',
            skipAdditionalCondition: true
        });
        searchQueries.push({
            aggregations: ["storeCode", "storeDisplay", "storeType"],
            indexName: indexMap.hktvProductStoreNameReplicaIndice || "hktvProductStoreNameReplica",
            keyword: keyword || '',
            page: {
                pageNumber: 0,
                pageSize: 8
            }
        })
        const url = `${hktvCommon.env.keywordSearchServerDomainUrl}/api/search`;
        const options = {
            method: 'POST',
            headers: {
                "accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': 'ApiKey ' + hktvCommon.env.keywordSearchServerApiKey
            },
            body: JSON.stringify({
                requests: searchQueries
            })
        }
        return fetch(url, options)
            .then(response => response.json())
            .then(data => KeywordSearchAdapter.parseSuggestionResponse(data));
    }

    private static parseSuggestionResponse(responseData: any): KeywordSearchSuggestionResponse {
        let suggestKeywords: KeywordSearchData[] = [];
        let suggestCategories: KeywordSearchData[] = [];
        let suggestStores: KeywordSearchStoreSearchData[] = [];
        let productSearch: KeywordSearchProductSearchData[] = [];


        const emptyResponse: KeywordSearchSuggestionResponse = {
            keyword: '',
            keywordDataList: [],
            storeDataList: [],
            productDataList: [],
            keywordSuggestDataList: []
        };
        if (!responseData.results) {
            return emptyResponse;
        }

		const [productSearchCodeResponse, suggestKeywordsResponse, suggestCategoriesFacet, suggestStoresReponse] = responseData.results;
		const keyword: string = suggestKeywordsResponse.request ? suggestKeywordsResponse.request.keyword : '';

        if(productSearchCodeResponse.hits) {
            let hit = productSearchCodeResponse.hits.shift();
            if(hit.source.allProductSearchCode.indexOf(keyword) > -1) {
                let productName = hktvCommon.env.currentLanguage === 'en' ? hit.source.nameEn : hit.source.nameZh;
                productSearch.push({
                    'name': productName,
                    'code': hit.source.code || ''
                });
            }
        }

        for (let key in suggestKeywordsResponse.hits) {
            let hit = suggestKeywordsResponse.hits[key];
            if (hit.source.query !== keyword) {
                suggestKeywords.push({
                    'keyword': keyword || '',
                    'name': hit.source.query || '',
                    'code': hit.source.code || ''
                });
                if (suggestKeywords.length >= 3) break;
            }
        }
		if (suggestCategoriesFacet.aggregations) {
			for (let key in suggestCategoriesFacet.aggregations.categoryStructureLevel1Display) {
				let catLevel1Display = JSON.parse(key);
				const language = hktvCommon.env.currentLanguage;
				const categoryNameEn = decodeURIComponent(catLevel1Display[1].nameEn);
				const categoryNameZh = decodeURIComponent(catLevel1Display[1].nameZh);
				let categoryName = language === 'en' ? categoryNameZh || categoryNameEn : categoryNameZh;
				let catLevel1 = catLevel1Display[1].code;

				suggestCategories.push({
					'keyword': keyword || '',
					'name': categoryName || '',
					'code': catLevel1 || ''
				});
				if (suggestCategories.length >= 5) break;
			}
		}

        for (let key in suggestStoresReponse.hits) {
			let storeDisplay = JSON.parse(suggestStoresReponse.hits[key].source.storeDisplay);
			let isDuplicates = suggestStores.some(store => store.code === suggestStoresReponse.hits[key].source.storeCode)
			if (!isDuplicates) {
				suggestStores.push({
					'code': suggestStoresReponse.hits[key].source.storeCode || '',
					'alt': decodeURIComponent(storeDisplay.nameZh) || '',
					'url': storeDisplay.storeLogoUrl || ''
				});
			}
			if (suggestStores.length >= 8) break;
		}

        return {
            keyword,
            keywordDataList: suggestCategories,
            storeDataList: suggestStores,
            productDataList: productSearch,
            keywordSuggestDataList: suggestKeywords
        };
    }

    private static parseKWSResponse(kwsReponse, searchJson): any {
        let results = [];
        kwsReponse.results.forEach(queryResult => {
            let hits = [];
            let responseHits = queryResult.hits;
            for (let key in responseHits) {
                hits.push(responseHits[key].source);
            }
            let modifiedQueryResult = {
                ...queryResult,
                hits: hits
            }
            results.push(modifiedQueryResult)
        })
        let parsedResponse = {
            results: results
        }
        if (kwsReponse.withProductIdSearch) {
            parsedResponse = {
                ...parsedResponse,
                withProductIdSearch: kwsReponse.withProductIdSearch
            };
        }
        if (kwsReponse.withPurchasedListSearch) {
            parsedResponse = {
                ...parsedResponse,
                withPurchasedListSearch: kwsReponse.withPurchasedListSearch
            };
        }
        if (kwsReponse.withPriorSKUSearch) {
            parsedResponse = {
                ...parsedResponse,
                withPriorSKUSearch: kwsReponse.withPriorSKUSearch,
            };
        }
        return KeywordSearchAdapter.parseSearchResponse(parsedResponse, null, searchJson);

    }

    private static parseSearchResponse(responseData: any, sourceId ?: string, searchFilters ?: any): KeywordSearchResponse {
        let productsResponseData: Array<any>;
        let responseDataBatches: Array<any>;
        let recentProductsResponseData: Array<any> = [];
        let purchasedProductsResopnseData: Array<any> = [];
        let priorProductsResponseData: Array<any> = [];
        let productsUserData: Array<any> = [];
        let mabsData: {
            [key: string]: {
                mabsRefId?: string;
                position?: string;
                objectId?: string;
            }
        } = {};
        let responsePinData: Array<pinData> = [];
        let keyword: string = '';
        let queryID: string = '';
        let products_recent = new Array<ProductAdapter>();
        let products_purchased = new Array<ProductAdapter>();
        let products_mabs = new Array<ProductAdapter>();
        let products_mkt_pin = new Array<ProductAdapter>();
        let products_prior = new Array<ProductAdapter>();
        let products_orig = new Array<ProductAdapter>();
        let products = new Array<ProductAdapter>();
        let pagination: AlgoliaSearchPagination;
        let catgoryTree: CategoryTree;
        let filterBreadcrumb = new Array<CategoryElement>();
        let facetCategoryDepth0s = new Array<CategoryFacetValue>();
        let facetCategoryDepth1s = new Array<CategoryFacetValue>();
        let facetCategoryLeaves = new Array<LeafCategoryFacetValue>();
        let facetBrand = new Array<BrandFacetValue>();
        let facetCountryOfOrigin = new Array<CountryOfOriginFacetValue>();
        let facetColor = new Array<ColorFacetValue>();
        let facetDeliverableRegion = new Array<DeliverableRegionFacetValue>();
        let facetPrice = new Array<PriceFacetValue>();
        let promotionBanner: PromotionBanner = KeywordSearchAdapter.parsePromotionBanner({});
        let keywordBanner: KeywordBanner = KeywordSearchAdapter.parseKeywordBanner({});
        let userData;
        let facetdeliveryTimes: any = {};
        let facetMainlandProducts = new Array<MainlandProductsFacetValue>();
        let indexMap = this.indexNameMapping();

        if (responseData.results) {//algolia multiple queries response
            keyword = responseData.results[0].request.keyword || '';
            productsResponseData = responseData.results[0].hits || [];
            if (responseData.withProductIdSearch) {
                recentProductsResponseData = responseData.results[1].hits || [];

                // filter no stock recent view sku
                recentProductsResponseData = recentProductsResponseData.filter((el) => {
                    let {numberOfVariants, purchaseOption, stock: {stockLevelStatus}} = el;
                    let code;
                    if (numberOfVariants > 1) {
                        return purchaseOption != 1 && purchaseOption != 2
                    } else if (stockLevelStatus) {
                        code = stockLevelStatus.code;
                    }
                    return code !== "stockNotifiable" && code !== "outOfStock"
                });
            }

            if (responseData.withPurchasedListSearch) {
                if (responseData.withProductIdSearch) {
                    purchasedProductsResopnseData = responseData.results[2].hits || [];
                } else {
                    purchasedProductsResopnseData = responseData.results[1].hits || [];
                }

                // filter no stock recent view sku
                purchasedProductsResopnseData = purchasedProductsResopnseData.filter((el) => {
                    let {numberOfVariants, purchaseOption, stock: {stockLevelStatus}} = el;
                    let code;
                    if (numberOfVariants > 1) {
                        return purchaseOption != 1 && purchaseOption != 2
                    } else if (stockLevelStatus) {
                        code = stockLevelStatus.code;
                    }
                    return code !== "stockNotifiable" && code !== "outOfStock"
                });
            }

            if (responseData.withPriorSKUSearch) {
                // assign isPriorSKU result from correct response data list
                priorProductsResponseData = responseData.results[responseData.results.length - 1].hits || [];

                //filter no stock priorSKU
                priorProductsResponseData = priorProductsResponseData.filter((el) => {
                    let {numberOfVariants, purchaseOption, stock: {stockLevelStatus}} = el;
                    let code;
                    if (numberOfVariants > 1) {
                        return purchaseOption != 1 && purchaseOption != 2
                    } else if (stockLevelStatus) {
                        code = stockLevelStatus.code;
                    }
                    return code !== "stockNotifiable" && code !== "outOfStock"
                });
            }

            responseDataBatches = responseData.results || [];

            // Getting User Data (MABS Data)
            productsUserData = responseData.results[0].userData || [];
            userData = productsUserData;
            if (productsUserData.length > 0) {
                productsUserData.forEach((el) => {
                    if (typeof (el) === "object" && 'mabsData' in el) {
                        const rawMabsData = el['mabsData'];
                        for (const key in rawMabsData) {
                            if (rawMabsData[key].mabsRefId !== undefined) {
                                mabsData[key] = {...rawMabsData[key]};
                            }
                        }
                    }
                })
            }

            // Getting Marketing Pin Data
            responsePinData = responseData.results[0].pinData || [];

            let totalProductCount = responseData.results[0].totalValue;
            let totalPageNo = Math.floor(totalProductCount / this.hitsPerPage);

            pagination = {
                'totalProductCount': totalProductCount,
                'totalPageNo': (totalProductCount % this.hitsPerPage > 0 ? totalPageNo + 1 : totalPageNo) || 0,
                'pageNo': Number(searchFilters.page) || 0,
                'productPerPage': this.hitsPerPage || 0
            };
        } else {
            productsResponseData = [];
            responseDataBatches = [];
            pagination = {
                'totalProductCount': 0,
                'totalPageNo': 0,
                'pageNo': 0,
                'productPerPage': 0
            };
        }

        let mabsSkuMap = [];
        let cat2SkuMap = {};
        let cat3SkuMap = {};
        let priorProductCatSkuMap = {};
        for (let index in productsResponseData) {
            let product = productsResponseData[index];
            let parsedProduct = KeywordSearchAdapter.parseProductResponse(product, mabsData);

            if (parsedProduct.mabsAdPosition && responseData.results[0].request.page.pageNumber == 0) {
                products_mabs.push(parsedProduct);
                mabsSkuMap.push(parseInt(parsedProduct.mabsAdPosition));
            }
            else if (responsePinData.filter((sku) => {return sku.id == parsedProduct.objectID}).length > 0) {
                products_mkt_pin.push(parsedProduct);
            } else {
                products_orig.push(parsedProduct);

                // save subcat 2 for non-mabs product HYBRIS-573
                if (Array.isArray(recentProductsResponseData) && recentProductsResponseData.length > 0
                    && parseInt(index) < 20 && product.primaryCatCode) {
                    let subCat2 = product.primaryCatCode.substring(0, 8);
                    cat2SkuMap[subCat2] = true;
                }

                if (Array.isArray(purchasedProductsResopnseData) && purchasedProductsResopnseData.length > 0
                    && parseInt(index) < 20 && product.primaryCatCode) {
                    let subCat2 = product.primaryCatCode.substring(0, 8);
                    cat3SkuMap[subCat2] = true;
                }

                if (Array.isArray(priorProductsResponseData) && priorProductsResponseData.length > 0
                    && parseInt(index) < 20 && product.primaryCatCode) {
                    let subCat2 = product.primaryCatCode.substring(0, 8);
                    priorProductCatSkuMap[subCat2] = true;
                }
            }
        }

        for (let index in recentProductsResponseData) {
            let product = recentProductsResponseData[index];
            if (product.primaryCatCode) {
                let subCat2 = product.primaryCatCode.substring(0, 8);
                if (cat2SkuMap[subCat2]) {
                    let recentLabel = searchResultMessage ? searchResultMessage.recentLabel : "";
                    products_recent.push(KeywordSearchAdapter.parseProductResponse(
                        product, mabsData, recentLabel));
                }
            }
        }

        for (let index in purchasedProductsResopnseData) {
            let product = purchasedProductsResopnseData[index];
            if (product.primaryCatCode) {
                let subCat2 = product.primaryCatCode.substring(0, 8);
                if (cat3SkuMap[subCat2]) {
                    let recentLabel = searchResultMessage ? searchResultMessage.recentLabel : "";
                    products_purchased.push(KeywordSearchAdapter.parseProductResponse(
                        product, mabsData, "", recentLabel, sourceId));
                }
            }
        }

        for (let index in priorProductsResponseData) {
            let product = priorProductsResponseData[index];
            if (product.primaryCatCode) {
                let subCat2 = product.primaryCatCode.substring(0, 8);
                if (priorProductCatSkuMap[subCat2]) {
                    products_prior.push(KeywordSearchAdapter.parseProductResponse(
                        product, mabsData, "", "", "", true));
                }
            }
        }

        let recentNpurchased = products_purchased ? products_purchased.length : 0;
        if (recentNpurchased < 2) {
            if (products_recent.length > 0) {
                if (recentNpurchased < 1) {
                    recentNpurchased = recentNpurchased + products_recent.length;
                } else {
                    recentNpurchased += 1;
                }
            }
        }

        // count priorSKU for display and pass productList to store in localStorage for del-dup page 2
        let priorSKUCount = 0;
        if (products_prior && products_prior.length > 0) {
            priorSKUCount = products_prior.length <= 8 ? products_prior.length : 8;
        }

        // normal list + recentlyView + purchasedList + priorSKU after filtering with cat
        pagination.totalProductCount += recentNpurchased + priorSKUCount;

        // count duplicated products for totalProductCount calculation
        var numOfDuplicated = 0;

        // count for total product in current page
        let count = products_mabs.length + products_orig.length + recentNpurchased + priorSKUCount;
        if (count <= 0) {
            $('meta[name="robots"]').attr('content', 'noindex,nofollow');
        }

        // assign mabs pin product into product list first, according to the pin position
        if (mabsSkuMap && products_mabs.length > 0) {
            for (let pos in mabsSkuMap) {
                products[mabsSkuMap[pos] - 1] = products_mabs.shift();
            }
        }

        // assign remaining products into product list
        for (let i = 0; i < count; i++) {
            let popProduct;
            var isDuplicated;
            if (products_purchased.length > 0) {
                isDuplicated = products.filter(function (product) {
                    return product.code == products_purchased[0].code;
                })
                if (isDuplicated.length == 0 || typeof isDuplicated === 'undefined') {
                    if (typeof products[i] == "undefined") {
                        popProduct = products_purchased.shift();
                        recentNpurchased--;
                    }
                } else {
                    products_purchased.shift();
                    ++numOfDuplicated;
                    recentNpurchased--;
                    i--;
                    continue;
                }
            } else if (products_recent.length > 0 && recentNpurchased > 0) {
                isDuplicated = products.filter(function (product) {
                    return product.code == products_recent[0].code;
                })
                if (isDuplicated.length == 0 || typeof isDuplicated === 'undefined') {
                    if (typeof products[i] == "undefined") {
                        popProduct = products_recent.shift();
                        recentNpurchased--;
                    }
                } else {
                    products_recent.shift();
                    recentNpurchased--;
                    ++numOfDuplicated;
                    i--;
                    continue;
                }
            } else if (products_mkt_pin.length > 0 && responsePinData.filter((sku) => {return sku.position-1 <= i}).length > 0) {
                const matchPins = responsePinData.filter((sku) => {return sku.position-1 <= i});
                if (matchPins.length > 0) {
                    popProduct = products_mkt_pin.filter((product) => {return product.objectID == matchPins[0].id}).shift()
                    responsePinData.splice(responsePinData.indexOf(matchPins[0], 0), 1);
                }
            } else if (products_prior.length > 0 && priorSKUCount > 0) {
                isDuplicated = products.filter(function (product) {
                    return product.code == products_prior[0].code;
                })
                if (isDuplicated.length == 0 || typeof isDuplicated === 'undefined') {
                    if (typeof products[i] == "undefined") {
                        popProduct = products_prior.shift();
                        priorSKUCount--;
                    }
                } else {
                    products_prior.shift();
                    priorSKUCount--;
                    ++numOfDuplicated;
                    i--;
                    continue;
                }
            } else if (products_orig.length > 0) {
                isDuplicated = products.filter(function (product) {
                    return product.code == products_orig[0].code;
                })
                if (isDuplicated.length == 0 || typeof isDuplicated === 'undefined') {
                    if (typeof products[i] == "undefined") {
                        popProduct = products_orig.shift();
                    }
                } else {
                    products_orig.shift();
                    ++numOfDuplicated;
                    i--;
                    continue;
                }
            }

			if (typeof popProduct !== "undefined") {
                while (typeof products[i] !== "undefined" && i < count) {
                    i++;
                }
                if (i < count) products[i] = popProduct;
            }
        }

        pagination.totalProductCount -= numOfDuplicated;

        for (let index in responseDataBatches) {
            let filtersResponse = responseDataBatches[index].request.filter;
            let facetsResponse = responseDataBatches[index].aggregations || {};

            if (index == '0') {
                if (searchFilters.category && !this.isZoneCategory(searchFilters.category)) {
                    // if filter category existed and not zone category
                    // can construct tree at once
                    catgoryTree = this.constructCategoryTree(facetsResponse, filtersResponse.category.length > 0 ? filtersResponse.category[0] : "");

                } else if (searchFilters.category) {
                    // if filter category existed and is zone category
                    // construct tree for that zone category first
                    catgoryTree = this.constructCategoryTreeRoots(facetsResponse, filtersResponse.category.length > 0 ? filtersResponse.category[0] : "");

                } else {
                    // no filter category
                    // construct tree for all zone category first
                    catgoryTree = this.constructCategoryTreeRoots(facetsResponse);
                }

                filterBreadcrumb = this.getCategoryBreadcrumbFromTree(catgoryTree)
                // update facets
                facetBrand = this.getBrandFacetFromResponse(facetsResponse);
                facetCountryOfOrigin = this.getCountryOfOriginFacetFromResponse(facetsResponse);
                facetColor = this.getColorFacetFromResponse(facetsResponse);
                facetDeliverableRegion = this.getDeliverableRegionFacetFromResponse(facetsResponse);
                facetPrice = this.getPriceFacetFromResponse(facetsResponse);
                facetdeliveryTimes = this.getFacetdeliveryTimesResponse(facetsResponse);
                facetMainlandProducts = this.getMainlandProductsFacetResponse(facetsResponse);
                if (responseDataBatches[index].request.indexName === indexMap.hktvProductIndice && responseDataBatches[index].userData && responseDataBatches[index].userData[0].keywordBannerData) {
                    keywordBanner = this.parseKeywordBanner(responseDataBatches[index]);
                }
            } else {
                if (responseDataBatches[index].request.indexName === indexMap.hktvPromotionBannersIndice && responseDataBatches[index].hits.length > 0) {
                    promotionBanner = this.parsePromotionBanner(responseDataBatches[index]);
                }
                // else if (KeywordSearchAdapter.isZoneCategory(searchFilters.category) && !KeywordSearchAdapter.isZoneCategory(filtersResponse.category) || !searchFilters.category && filtersResponse.category) {
                //     // getting leaves category
                //     KeywordSearchAdapter.constructCategoryTreeLeaves(catgoryTree, facetsResponse, filtersResponse.category);
                // }
                else {
                    if (searchFilters.brand && filtersResponse && !filtersResponse.brand) {
                        // update brand facet
                        facetBrand = this.getBrandFacetFromResponse(facetsResponse);
                    }
                    if (searchFilters.countryOfOrigin && filtersResponse && !filtersResponse.countryOfOrigin) {
                        // update CountryOfOrigin facet
                        facetCountryOfOrigin = this.getCountryOfOriginFacetFromResponse(facetsResponse);
                    }
                    if (searchFilters.color && filtersResponse && !filtersResponse.color) {
                        // update color facet
                        facetColor = this.getColorFacetFromResponse(facetsResponse);
                    }
                    if (searchFilters.deliverableRegion && filtersResponse && filtersResponse.deliverableRegions && !filtersResponse.deliverableRegions.deliverableRegion) {
                        // update deliverableRegions facet
                        facetDeliverableRegion = this.getDeliverableRegionFacetFromResponse(facetsResponse);
                    }
                    if (searchFilters.price && filtersResponse && filtersResponse.range && !filtersResponse.range.sellingPrice) {
                        // update price facet
                        facetPrice = this.getPriceFacetFromResponse(facetsResponse);
                    }
                    if (searchFilters.deliverySchedules && filtersResponse && !filtersResponse.deliverySchedules) {
                        // update deliveryTimes facet
                        facetdeliveryTimes = this.getFacetdeliveryTimesResponse(facetsResponse);
                    }
                    if (searchFilters.mainlandProducts && filtersResponse && !filtersResponse.isMainlandSamePrice) {
                        // update mainlandProducts facet
                        facetMainlandProducts = this.getMainlandProductsFacetResponse(facetsResponse);
                    }
                }
            }
        }

        return {
            'products': products,
            'pagination': pagination,
            'keyword': keyword,
            'categoryTree': catgoryTree,
            'filterBreadcrumb': filterBreadcrumb,
            'brands': facetBrand,
            'countryOfOrigins': facetCountryOfOrigin,
            'colors': facetColor,
            'deliverableRegions': facetDeliverableRegion,
            'prices': facetPrice,
            'promotionBanner': promotionBanner,
            'keywordBanner': keywordBanner,
            'userData': userData,
            'deliverySchedules': facetdeliveryTimes,
            'mainlandProducts': facetMainlandProducts
        }
    }

    private static getMabsRefId(objectId: string, mabsData) {
        return (objectId in mabsData ? (mabsData[objectId].mabsRefId || "") : "")
    }

    private static getMabsAdPosition(objectId: string, mabsData) {
        return (objectId in mabsData ? (mabsData[objectId].position || "") : "")
    }

    private static parseProductResponse(product, mabsData, additionalLabel ?, additionalLabelForPurchasedList ?, sourceId ?, isPriorSKU ?) {
        return {
            'code': product.code,
            'objectID': product.objectID,
            'queryID': "",
            'baseProduct': product.baseProduct,
            'primaryCatCode': product.primaryCatCode,
            'nameZh': product.nameZh,
            'nameEn': product.nameEn,
            'brand': product.brand,
            'brandEn': product.brandEn,
            'brandZh': product.brandZh,
            'descriptionEn': product.descriptionEn,
            'descriptionZh': product.descriptionZh,
            'storeCode': product.storeCode,
            'storeCodeOrig': product.storeCodeOriginal,
            'storeNameEn': product.storeNameEn,
            'storeNameZh': product.storeNameZh,
            'storeType': product.storeType,
            'storeTypeDisplayEn': product.storeTypeDisplayEn,
            'storeTypeDisplayZh': product.storeTypeDisplayZh,
            'storeRating': product.storeRating,
            'averageRating': product.averageRating,
            'numberOfReviews': product.numberOfReviews,
            'thresholdPromotionEn': product.thresholdPromotionEn,
            'thresholdPromotionZh': product.thresholdPromotionZh,
            'buyMoreSaveMoreEn': product.buyMoreSaveMoreEn,
            'buyMoreSaveMoreZh': product.buyMoreSaveMoreZh,
            'perfectPartnerPromotionEn': product.perfectPartnerPromotionEn,
            'perfectPartnerPromotionZh': product.perfectPartnerPromotionZh,
            'bundlePromotionEn': product.bundlePromotionEn,
            'bundlePromotionZh': product.bundlePromotionZh,
            'promotionStyle': product.promotionStyle,
            'promotionTextEn': product.promotionTextEn,
            'promotionTextZh': product.promotionTextZh,
            'priceList': product.priceList,
            'mainlandPriceList': product.mainlandPriceList,
            'savedPrice': product.savedPrice,
            'priceUpdatedDate': product.priceUpdatedDate,
            'images': product.images,
            'videos': product.videos,
            'labels': product.labels,
            'stock': product.stock,
            'hasStock': product.hasStock,
            'hasVariantInStock': product.hasVariantInStock,
            'invisible': product.invisible,
            'isPrimaryVariant': product.isPrimaryVariant,
            'numberOfVariants': product.numberOfVariants,
            'purchaseOption': product.purchaseOption,
            'packingSpecEn': product.packingSpecEn,
            'packingSpecZh': product.packingSpecZh,
            'urlEn': product.urlEn,
            'urlZh': product.urlZh,
            'deliveryLabelCode': product.deliveryLabelCode,
            'deliveryLabelScheduleCode': product.deliveryLabelScheduleCode,
            'deliverableRegions': product.deliverableRegions,
            'salesNumberStringZh': product.salesNumberStringZh,
            'salesNumberStringEn': product.salesNumberStringEn,
            'mabsRefId': KeywordSearchAdapter.getMabsRefId(product.objectID, mabsData),
            'mabsAdPosition': KeywordSearchAdapter.getMabsAdPosition(product.objectID, mabsData),
            'marketingLabelUids': product.marketingLabelUids,
            'additionalLabel': additionalLabel || "",
            'inStorePickUpCheckoutLink': product.inStorePickUpCheckoutLink,
            'externalAffiliateRedirectLink': product.externalAffiliateRedirectLink || "",
            'displayStoreSquareLogoUrl': product.displayStoreSquareLogoUrl || "",
            'officialWebsiteAffiliateUrl': product.officialWebsiteAffiliateUrl || "",
            'additionalLabelForPurchasedList': additionalLabelForPurchasedList || "",
            'sourceId': sourceId || "",
            'overseasRegionCodes': product.overseasRegionCodes,
            'isThirdPartyLogisticsWarehouse': product.isThirdPartyLogisticsWarehouse ? "is3PLProduct" : "",
            'isPriorSKU': product.isPriorSKU ? "isPriorSKU" : "",
            'pickLabelUids': product.pickLabelUids || [],
            'eCoinsRebateSchedule': product.eCoinsRebateSchedule || [],
            'isMainlandSamePrice': product.isMainlandSamePrice
        }
    }

    private static parsePromotionBanner(result: any):
        AlgoliaPromotionBanner {
        let banner: AlgoliaPromotionBanner = {
            alt: '',
            category: '',
            url: '',
            imageUrl: '',
            backgroundColor: '',
            promoDetails: '',
            promoTitle: '',
            tandCLink: '',
            mabsRefId: ''
        }

        if (Array.isArray(result.hits) && result.hits.length > 0) {
            const hit = result.hits[0];

            banner.alt = this.getCurrentLanguageDisplay(hit, "alt") || '';
            banner.category = hit.category || '';
            banner.url = this.getCurrentLanguageDisplay(hit, "bannerUrl") || '';
            banner.imageUrl = this.getCurrentLanguageDisplay(hit, "imageUrlWeb") || '';
            banner.backgroundColor = hit.backgroundColor || '';
            banner.promoDetails = this.getCurrentLanguageDisplay(hit, "promoDetails") || '';
            banner.promoTitle = this.getCurrentLanguageDisplay(hit, "promoTitle") || '';
            banner.tandCLink = this.getCurrentLanguageDisplay(hit, "TandCLink") || '';
            banner.mabsRefId = hit.mabsRefId || '';
        }
        return banner;
    }

    private static parseKeywordBanner(result: any): KeywordBanner {
        let banner: KeywordBanner = {
            alt: '',
            backgroundColor: '',
            bannerUrl: '',
            imageUrlWeb: '',
            category: '',
            promoDetails: '',
            promoTitle: '',
            tandCLink: '',
            mabsRefId: ''
        }

        if (Array.isArray(result.userData) && result.userData.length > 0) {
            const userData = result.userData[0];
            const hit = userData.keywordBannerData;
            if (hit) {
                banner.alt = this.getCurrentLanguageDisplay(hit, "alt") || '';
                banner.backgroundColor = hit.backgroundColor || '';
                banner.bannerUrl = this.getCurrentLanguageDisplay(hit, "bannerUrl") || '';
                banner.imageUrlWeb = this.getCurrentLanguageDisplay(hit, "imageUrlWeb") || '';
                banner.category = hit.category || '';
                banner.promoDetails = this.getCurrentLanguageDisplay(hit, "promoDetails") || '';
                banner.promoTitle = this.getCurrentLanguageDisplay(hit, "promoTitle") || '';
                banner.tandCLink = this.getCurrentLanguageDisplay(hit, "tandCLink") || '';
                banner.mabsRefId = hit.mabsRefId || '';
            }
        }
        return banner;
    }

    /**
     * get main category priority from category code
     * @param {string} categoryCode categoryCode
     * @return {number} main category priority (higher value has higher priority)
     */
    private static isZoneCategory(categoryCode: string): boolean {
        let code = categoryCode || '';
        switch (code) {
            case 'supermarket':
            case 'personalcarenhealth':
            case 'beautynhealth':
            case 'mothernbaby':
            case 'pets':
            case 'homenfamily':
            case 'housewares':
            case 'deals':
            case 'sportsntravel':
            case 'toysnbooks':
            case 'fashion':
            case 'disney':
            case 'thirteenlandmarks':
            case 'gadgetsandelectronics':
            case 'finance':
                return true;
            default:
                return false;
        }
    }

    /**
     * Convert search filters Json to algolia filter string
     * @param {searchFilters} searchJson search filters Json
     * @return {string} algolia filter string
     */
    private static constructSearchFiltersString(searchJson: KeywordSearchFilters, facetDisabled ?: string, useProductIdList ?: boolean, usePurchasedList ?: boolean): any {
        let category = searchJson.category || '';
        let brand = searchJson.brand || [];
        let countryOfOrigin = searchJson.countryOfOrigin || [];
        let color = searchJson.color || [];
        let deliverableRegion = searchJson.deliverableRegion || [];
        let deliveryTime = searchJson.deliveryTime || [];
        let mainlandProducts = searchJson.mainlandProducts || [];
        let salesChannel = searchJson.salesChannel || []

        let filterObject = {};

        if (category != '' && facetDisabled != 'category') {
            filterObject = {
                ...filterObject,
                "category": [category]
            }
        }

        if (useProductIdList && Array.isArray(searchJson.productIdList) && searchJson.productIdList.length > 0) {
            filterObject = {
                ...filterObject,
                "code": searchJson.productIdList
            }
        }

        if (usePurchasedList && Array.isArray(searchJson.purchasedList) && searchJson.purchasedList.length > 0) {
            if (filterObject && filterObject["code"]) {
                const skuCodeFilter = filterObject["code"];
                searchJson.purchasedList.forEach((item) => skuCodeFilter.push(item))
                filterObject = {
                    ...filterObject,
                    "code": skuCodeFilter
                }
            } else {
                filterObject = {
                    ...filterObject,
                    "code": searchJson.purchasedList
                }
            }
        }

        if (brand.length > 0 && facetDisabled != 'brand') {
            filterObject = {
                ...filterObject,
                "brand": brand
            }
        }
        if (countryOfOrigin.length > 0 && facetDisabled != 'countryOfOrigin') {
            filterObject = {
                ...filterObject,
                "countryOfOrigin": countryOfOrigin
            }
        }
        if (color.length > 0 && facetDisabled != 'color') {
            filterObject = {
                ...filterObject,
                "color": color
            }
        }
        if (deliverableRegion.length > 0 && facetDisabled != 'deliverableRegion') {
            filterObject = {
                ...filterObject,
                "deliverableRegions.deliverableRegion": deliverableRegion
            }
        }
        if (deliveryTime.length > 0 && facetDisabled != 'deliveryTime') {
            const standardExpressHour = [];
            const deliveryTimeSlot = []
            for (let index in deliveryTime) {
                const schedule = deliveryTime[index];

                if (schedule === 'standardExpressHour') {
                    standardExpressHour.push(AlgoliaSearchAdapter.getNowDateHour())
                } else {
                    deliveryTimeSlot.push(schedule)
                }
            }
            if (standardExpressHour.length > 0) {
                filterObject = {
                    ...filterObject,
                    "standardExpressHour": standardExpressHour,
                }
            }
            if (deliveryTimeSlot.length > 0) {
                filterObject = {
                    ...filterObject,
                    "deliveryTime.code": deliveryTimeSlot
                }
            }
        }

        if (mainlandProducts.length > 0 && facetDisabled != 'mainlandProducts') {
            for (let index in mainlandProducts) {
                const productType: string = mainlandProducts[index].toString();
                if (productType === "isMainlandSamePrice"){
                    filterObject = {
                        ...filterObject,
                        [productType]: ["true"]
                    }
                }
            }
        }

        if (salesChannel.length > 0 && facetDisabled != 'salesChannel') {
            filterObject = {
                ...filterObject,
                "salesChannel": salesChannel
            }
        }
        return filterObject || {};
    }

    private static getRangeOfSellingPrice(searchJson: KeywordSearchFilters, facetDisabled ?: string): any {
        let price = searchJson.price || '';
        let priceFilterString = "";
        if (price != '' && facetDisabled != 'price') {
            priceFilterString += price.split('-')[0];
            if (price.indexOf('<') >= 0) {
                priceFilterString = "" + priceFilterString.replace('<', '-')
            } else if (price.indexOf('>') >= 0) {
                priceFilterString = priceFilterString.replace('>', '') + '-'
            }
            else if(price.split('-').length > 1) {
                priceFilterString += '-' + price.split('-')[1]
            }
        }

        return ({"sellingPrice": priceFilterString});
    }

    /**
     * get main category priority from category code
     * @param {string} categoryCode categoryCode
     * @return {number} main category priority (higher value has higher priority)
     */
    private static getMainCategoryOrder(categoryCode: string): number {
        let code = categoryCode || '';
        switch (code) {
            case 'thirteenlandmarks' :
                return 0;
            case 'supermarket':
                return 1;
            case 'personalcarenhealth':
                return 2;
            case 'beautynhealth':
                return 3;
            case 'mothernbaby':
                return 4;
            case 'pets':
                return 5;
            case 'gadgetsandelectronics':
                return 6;
            case 'homenfamily':
                return 7;
            case 'housewares':
                return 8;
            case 'deals':
                return 9;
            case 'sportsntravel':
                return 10;
            case 'toysnbooks':
                return 11;
            case 'fashion':
                return 12;
            case 'finance':
                return 13;
            default:
                return 14;
        }
    }

    /**
     * get Algolia breadcrumb from category tree
     * @param {json} facetsResponse response facet json
     * @return {array<CategoryElement>} Algolia filter category tree
     */
    private static

    getCategoryBreadcrumbFromTree(tree
                                      :
                                      CategoryTree
    ):
        Array<CategoryElement> {
        let breadcrumb = new Array<CategoryElement>();
        let depth = tree['filterDepth'] || 0;
        let ptr = tree['categories'];

        for (let index = 0; index < depth; index++
        ) {
            breadcrumb.push({
                'code': ptr[0]['code'] || '',
                'name': ptr[0]['name'] || ''
            });
            ptr = ptr[0]['child'];
        }
        return breadcrumb;
    }

    /**
     * get Algolia filter category tree
     * @param {json} facetsResponse response facet json
     * @return {array<CategoryElement>} Algolia filter category tree
     */
    private static constructCategoryTree(facetsResponse: any, filtersCategory: string):
        CategoryTree {
        let treeRef = new Array<CategoryTreeElement>();
        let filterDepth = 0;
        let ptr = treeRef;
        let endPtr;
        let parentTreeParsed = false;
        let leafCatList = [];

        for (let key in facetsResponse.categoryStructureDisplay) {
            let categoryStructureDisplay = JSON.parse(key);

            for (let index in categoryStructureDisplay) {
                if (categoryStructureDisplay[index].code == filtersCategory) {

                    if (!parentTreeParsed) {
                        filterDepth = parseInt(index) + 1;
                        for (let i = 0; i <= parseInt(index); i++) {
                            ptr.push({
                                'code': categoryStructureDisplay[i].code || '',
                                'name': this.getCurrentLanguageDisplay(categoryStructureDisplay[i], 'name'),
                                'count': 0,
                                'child': new Array<CategoryTreeElement>()
                            });

                            endPtr = ptr[0];
                            ptr = ptr[0]['child'];
                        }
                        parentTreeParsed = true;
                    }

                    let catleaf = _.last(categoryStructureDisplay);
                    if (catleaf['code'] != filtersCategory)
                        leafCatList.push({
                            'code': catleaf['code'] || '',
                            'name': this.getCurrentLanguageDisplay(catleaf, 'name'),
                            'count': facetsResponse.categoryStructureDisplay[key] || 0,
                            'child': new Array<CategoryTreeElement>()
                        });
                }
            }
        }
        if (parentTreeParsed) // ensure endPtr is valid
            endPtr['child'] = leafCatList;

        return {
            'filterDepth': filterDepth,
            'categories': treeRef
        };
    }


    /**
     * get Algolia filter category tree
     * @param {json} facetsResponse response facet json
     * @return {array<CategoryElement>} Algolia filter category tree
     */
    private static constructCategoryTreeLeaves(treeRef: CategoryTree, facetsResponse: any, filtersCategory: string):
        void {
        let cat0;
        let cat1 = filtersCategory;

        if (treeRef['categories'].length == 0
        )
            return;

        // find cat0 of this response by finding parent of cat1
        for (let key in facetsResponse.categoryStructureDisplay) {
            if (key.indexOf(filtersCategory) >= 0) {
                let categoryStructureDisplay = JSON.parse(key);
                cat0 = categoryStructureDisplay[0].code;
                break;
            }
        }
        let cat1Json;
        for (let index in treeRef['categories']) {
            if (treeRef['categories'][index].code == cat0) {
                cat1Json = treeRef['categories'][index].child[0];
                break;
            }
        }

        if (cat1Json) {
            let catLeafList = cat1Json.child;

            for (let key in facetsResponse.categoryStructureDisplay) {
                let categoryStructureDisplay = JSON.parse(key);
                let catleaf = _.last(categoryStructureDisplay);

                if (categoryStructureDisplay[1]) {
                    if (categoryStructureDisplay[1].code == cat1) //make sure all leaf under this cat1
                        catLeafList.push({
                            'code': catleaf['code'] || '',
                            'name': KeywordSearchAdapter.getCurrentLanguageDisplay(catleaf, 'name'),
                            'count': facetsResponse.categoryStructureDisplay[key] || 0,
                            'child': new Array<CategoryTreeElement>()
                        });
                }
            }
        }
    }

    /**

     /**
     * get Algolia category tree
     * @param {json} treeRef category tree node
     * @param {json} facetsResponse response facet json
     * @param {string} filtersCategory
     */
    private static constructCategoryTreeRoots(facetsResponse: any, filtersCategory ?: string): CategoryTree {
        let treeRef = [];
        let catArray = new Array<CategoryTreeElement>();

        if (filtersCategory) {
            if (KeywordSearchAdapter.isZoneCategory(filtersCategory)) {
                for (let key in facetsResponse.categoryStructureLevel0Display) {
                    let categoryLevel0 = JSON.parse(key);

                    if (categoryLevel0[0].code == filtersCategory) {
                        treeRef.push({
                            'code': categoryLevel0[0].code || '',
                            'name': KeywordSearchAdapter.getCurrentLanguageDisplay(categoryLevel0[0], 'name'),
                            'count': facetsResponse.categoryStructureLevel0Display[key] || 0,
                            'child': new Array<CategoryTreeElement>()
                        });
                        break;
                    }
                }

                for (let key in facetsResponse.categoryStructureLevel1Display) {
                    let categoryLevel1 = JSON.parse(key);

                    if (categoryLevel1[0].code == filtersCategory) {
                        treeRef[0].child.push({
                            'code': categoryLevel1[1].code || '',
                            'name': KeywordSearchAdapter.getCurrentLanguageDisplay(categoryLevel1[1], 'name'),
                            'count': facetsResponse.categoryStructureLevel1Display[key] || 0,
                            'child': new Array<CategoryTreeElement>()
                        })
                    }
                }
                if (treeRef.length > 0) {
                    treeRef[0].child.sort((a, b) => {
                        return b.count - a.count;
                    });

                    return {
                        'filterDepth': 1,
                        'categories': treeRef
                    };
                } else return {
                    'filterDepth': 0,
                    'categories': []
                };
            } else
                return {
                    'filterDepth': 0,
                    'categories': []
                };
        } else {
            for (let key in facetsResponse.categoryStructureLevel0Display) {
                let categoryLevel0 = JSON.parse(key);

                catArray.push({
                    'code': categoryLevel0[0].code || '',
                    'name': KeywordSearchAdapter.getCurrentLanguageDisplay(categoryLevel0[0], 'name'),
                    'count': facetsResponse.categoryStructureLevel0Display[key] || 0,
                    'child': new Array<CategoryTreeElement>()
                });
            }
            catArray.sort((a, b) => {
                let priorityA: number = KeywordSearchAdapter.getMainCategoryOrder(a.code);
                let priorityB: number = KeywordSearchAdapter.getMainCategoryOrder(b.code);
                return priorityA - priorityB;
            });

            treeRef = catArray;

            for (let key in facetsResponse.categoryStructureLevel1Display) {
                let categoryLevel1 = JSON.parse(key);

                for (let index in treeRef) {
                    if (treeRef[index].code == categoryLevel1[0].code) {
                        treeRef[index]['child'].push({
                            'code': categoryLevel1[1].code || '',
                            'name': KeywordSearchAdapter.getCurrentLanguageDisplay(categoryLevel1[1], 'name'),
                            'count': facetsResponse.categoryStructureLevel1Display[key] || 0,
                            'child': new Array<CategoryTreeElement>()
                        });
                    }
                }
            }

            for (let index in treeRef) {
                treeRef[index]['child'].sort((a, b) => {
                    return b.count - a.count;
                });
            }
            ;

            return {
                'filterDepth': 0,
                'categories': treeRef
            };
        }
    }


    /**
     * get KWS brand facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {BrandFacetValue} KWS brand facet json
     */
    private static getBrandFacetFromResponse(facetsResponse: any):
        Array<BrandFacetValue> {

        //brands display json
        let brands = new Array();
        for (let key in facetsResponse.brandDisplay
            ) {
            try {
                let brandResponse = JSON.parse(key);
                let brand: BrandFacetValue = {
                    'code': brandResponse.code || '',
                    'name': this.getCurrentLanguageDisplay(brandResponse, 'name') || '',
                    'priority': brandResponse.priority || 0,
                    'count': facetsResponse.brandDisplay[key] || 0
                }
                brands.push(brand);
            } catch (error) {
                Logger.error('Algolia brand facet', 'Json parse error');
            }
        }
        brands.sort((a, b) => {
            if (a.priority == b.priority)
                return b.count - a.count;
            else
                return b.priority - a.priority;
        });

        return brands;
    }

    /**
     * get Algolia country facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {CountryOfOriginFacetValue} Algolia country facet json
     */
    private static getCountryOfOriginFacetFromResponse(facetsResponse: any):
        Array<CountryOfOriginFacetValue> {

        //countryOfOrigin display json
        let countryOfOrigins = new Array<CountryOfOriginFacetValue>();
        for (let key in facetsResponse.countryOfOriginDisplay
            ) {
            try {
                let countryOfOriginResponse = JSON.parse(key);
                let countryOfOrigin: CountryOfOriginFacetValue = {
                    'code': countryOfOriginResponse.code || '',
                    'name': this.getCurrentLanguageDisplay(countryOfOriginResponse, 'name') || '',
                    'count': facetsResponse.countryOfOriginDisplay[key] || 0
                };
                countryOfOrigins.push(countryOfOrigin);
            } catch (error) {
                Logger.error('Algolia countryOfOrigin facet', 'Json parse error');
            }
        }
        return countryOfOrigins;
    }

    /**
     * get Algolia color facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {ColorFacetValue} Algolia color facet json
     */
    private static getColorFacetFromResponse(facetsResponse: any): Array<ColorFacetValue> {

        //color display json
        let colors = new Array<ColorFacetValue>();
        for (let key in facetsResponse.colorDisplay
            ) {
            try {
                let colorResponse = JSON.parse(key);
                let color: ColorFacetValue = {
                    'code': colorResponse.code || '',
                    'name': this.getCurrentLanguageDisplay(colorResponse, 'name') || '',
                    'imageUrl': colorResponse.imageUrl || '',
                    'count': facetsResponse.colorDisplay[key] || 0
                }
                colors.push(color);
            } catch (error) {
                Logger.error('Algolia color facet', 'Json parse error');
            }
        }
        return colors;
    }

    /**
     * get Algolia DeliverableRegion facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {DeliverableRegionFacetValue} Algolia DeliverableRegions facet json
     */
    private static getDeliverableRegionFacetFromResponse(facetsResponse: any):
        Array<DeliverableRegionFacetValue> {

        //deliverableRegion display json
        let deliverableRegions = new Array();
        for (let key in facetsResponse["deliverableRegions.deliverableRegionDisplay"]
            ) {
            try {
                let deliverableRegionResponse = JSON.parse(key);
                let deliverableRegion: DeliverableRegionFacetValue = {
                    'code': deliverableRegionResponse.code || '',
                    'name': this.getCurrentLanguageDisplay(deliverableRegionResponse, 'name') || '',
                    'count': facetsResponse["deliverableRegions.deliverableRegionDisplay"][key] || 0,
                }
                deliverableRegions.push(deliverableRegion);
            } catch (error) {
                Logger.error('Algolia deliverableRegion facet', 'Json parse error');
            }
        }
        return deliverableRegions;
    }

    /**
     * get Algolia DeliverableRegion facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {deliveryTimesFacetValue} Algolia DeliverableRegions facet json
     */
    private static getFacetdeliveryTimesResponse(facetsResponse: any): Array<deliveryTimesFacetValue> {
        let ddhh = AlgoliaSearchAdapter.getNowDateHour();
        let deliverySchedules = new Array();
        if(facetsResponse["standardExpressHour"] != null && facetsResponse["standardExpressHour"].hasOwnProperty(ddhh)){
			let deliveryLabel = deliveryLabelExplanations.find(deliveryLabel => deliveryLabel.deliveryMode === 'standard-express')
            let standardExpressHour: DeliverySchedulesFacetValue = {
                code: 'standardExpressHour',
                name: deliveryLabel.name,
                count: facetsResponse["standardExpressHour"][ddhh] || 0
            }
            deliverySchedules.push(standardExpressHour)
        }
		let deliveryTimeResponse = facetsResponse["deliveryTime.code"];
        if(deliveryTimeResponse != null){
            for(let deliveryTimeCode in deliveryTimeResponse){
				let deliveryLabel = deliveryLabelExplanations.find(deliveryLabel => deliveryLabel.deliveryMode === deliveryTimeCode)
                let deliveryTime: DeliverySchedulesFacetValue = {
                    code: deliveryTimeCode,
                    name: deliveryLabel.name,
                    count: deliveryTimeResponse[deliveryTimeCode]
                }
                deliverySchedules.push(deliveryTime)
            }
        }
        return deliverySchedules
    }

    /**
     * get Algolia MainlandProducts facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {MainlandProductsFacetValue} Algolia MainlandProducts facet json
     */
    private static getMainlandProductsFacetResponse(facetsResponse: any): Array<MainlandProductsFacetValue> {

        let mainlandProducts = new Array<MainlandProductsFacetValue>();
        if(facetsResponse["isMainlandSamePrice"] != null && facetsResponse["isMainlandSamePrice"]["1"] != null){
            const hasMainlandSamePriceText = typeof mainlandSamePriceText !== "undefined" && mainlandSamePriceText && mainlandSamePriceText !== "";
            let isMainlandSamePrice: MainlandProductsFacetValue = {
                'code': "isMainlandSamePrice",
                'name': hasMainlandSamePriceText ? mainlandSamePriceText : "",
                'count': facetsResponse["isMainlandSamePrice"]["1"] || 0
            };
            mainlandProducts.push(isMainlandSamePrice);
        }
        return mainlandProducts
    }

    /**
     * get Algolia price facet Json from response facet json
     * @param {json} facetsResponse response facet json
     * @return {PriceFacetValue} Algolia price facet json
     */
    private static getPriceFacetFromResponse(facetsResponse: any): Array<PriceFacetValue> {

        //price display json
        let prices = new Array<PriceFacetValue>();
        for (let key in facetsResponse.sellingPriceRange
            ) {
            let price: PriceFacetValue = {
                'price': key || '',
                'count': facetsResponse.sellingPriceRange[key] || 0
            };
            prices.push(price);
        }
        prices.sort((a, b) => {
            if (a.price.indexOf('<') > -1 || b.price.indexOf('>') > -1) {
                return -1;
            } else if (a.price.indexOf('>') > -1 || b.price.indexOf('<') > -1) {
                return 1;
            } else {
                return Number(a.price.split('-')[0]) - Number(b.price.split('-')[0]);
            }
        });
        return prices;
    }

    /**
     * decode Algolia params
     * @param {string} url
     * @return {json} decoded queryJson
     */
    private static decodeParamsFilter(request: any): any {
        let paramJson = {};
        if (!request) return paramJson;
        let filtersString = JsonUtils.stringToJson(request)['filterConditionalExpression'];
        if (filtersString && filtersString.length > 0) {
            let filters = decodeURIComponent(filtersString).replace(/[()]/g, '').split(/ AND | OR /);

            for (let key in filters) {
                let type, value;
                if (filters[key].indexOf(':') < 0 && filters[key].match(/[<>]/g)) {// expect price facet
                    type = filters[key].replace(/ /g, '').split(/[<>]/)[0];
                    let sep = (filters[key].indexOf('<') > -1 ? '<' : '>');
                    value = sep + filters[key].replace(/ /g, '').split(/[<>]/)[1];
                } else {
                    type = filters[key].split(':')[0];
                    value = filters[key].split(':')[1];
                    if (value.match(/ TO /g)) {
                        value = value.replace(/ TO /g, '-');
                    }
                }

                if (paramJson[type]) //not null or undefined
                    if (paramJson[type] instanceof Array)
                        paramJson[type].push(value);
                    else
                        paramJson[type] = [paramJson[type], value];
                else
                    paramJson[type] = value;
            }
        }
        return paramJson;
    }

    /**
     * return text according to language setting
     * @param {string} text json
     * @param {string} field name
     * @return {string} text
     */
    private static getCurrentLanguageDisplay(langObj: any, field: string):
        string {
        if (hktvCommon.env.currentLanguage == 'en')
            return decodeURIComponent(langObj[field + 'En']);
        else
            return decodeURIComponent(langObj[field + 'Zh']);
    }
}